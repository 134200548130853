import React, { useEffect } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import './css/App.css';
import Routes from "./routes";
import MySnackbarContent from './components/SnackBar';
import ProgressDialog from './components/ProgressDialog';
import {
  Snackbar,
  CssBaseline,
  Fade,
  LinearProgress,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core';
import { AppContext } from './AppContextProvider';
import { useContext } from 'react';
import { PATHS } from './Config';
// import { UserService } from './Services/MasterService';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	sectionMobile: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
		display: 'flex',
		},
	},
	accountCircle: {
		marginRight: theme.spacing(3)
	},
}));


function App(props) {

	const classes = useStyles();
	const history = useHistory();
	const {state,
		handleSBClose,
	} = useContext(AppContext);

	useEffect(() => {
		if(props.location.pathname !== PATHS.SCAN && (state.clientId === null || state.productId === null || state.sequence === null)) {
			history.push(PATHS.LANDING);
		} else {
			history.push(props.location.pathname);
		}
	}, [true])

    return (
        <ThemeProvider theme={state.theme}>
			<div>
				<div className={classes.root}>
					<Fade
						in={state.linearLoading}
						style={{
							transitionDelay: state.linearLoading ? '50ms' : '0ms',
						}}
						unmountOnExit
						>
						<LinearProgress 
							id="test" 
							style={{height: 5, position: "fixed", top: 0, width: "100%"}} />
					</Fade>
					<CssBaseline />
				</div>
				<div style={{display: 'flex', justifyContent: 'center'}}>
					<img
						style={{ margin: 20, width: '65vw', }}
						src={process.env.PUBLIC_URL + '/images/logo.png'}
						alt="EAuthenticity Logo"
					/>
				</div>
				<Routes style={{width: "50%"}}/>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={state.sb.open}
					autoHideDuration={state.sb.autohide}
					onClose={handleSBClose}>
						<MySnackbarContent
							onClose={handleSBClose}
							variant={state.sb.varient}
							message={state.sb.message}
						/>
				</Snackbar>
				<ProgressDialog open={state.progressOpen} message={state.progressMessage}/>
				{
					state.loading && (
						<div style={{zIndex: 999, position: 'absolute', top: '50%', left: '35%'}}>
							<img 
								width="100"
								src={process.env.PUBLIC_URL + '/images/loader.gif'}
								alt="Loader" />
						</div>
					)
				}
				{
					state.loading && (
						<div style={{zIndex: 888, width: '100vw', height: '110vh', backgroundColor: 'rgba( 26, 26, 26, 0.7 )', position: 'absolute', top: 0}}>
						</div>
					)
				} 
			</div>
        </ThemeProvider>
    );
}

export default withRouter(App);
