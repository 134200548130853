import React, { useContext, useState } from "react";
import "../css/login.css";
import { 
  Grid, Typography, 
 } from "@material-ui/core";
import { AppContext } from "../AppContextProvider";
import queryString from 'query-string';

export default function Landing(props){

  const {setTitle, 
    mobileView
  } = useContext(AppContext);
  const [message, setMessage] = useState("");

  React.useEffect(() => {
    setTitle('Welcome');
    console.log(props.location.search);
    if(props.location.search){
      let qString = queryString.parse(props.location.search);
      const opened = qString.opened || null;
      if(opened){
        setMessage('Your product has already been authenticated.')
      }
    }
  }, [true])

  return (
    <div className="Login" style={{height: '100vh', marginLeft: mobileView ? 0 : 16, textAlign: 'center'}}>
      <Grid container spacing={2} direction="column" justify="center" alignItems="center" style={{height: '70vh'}}>
        {
          message && (
            <Grid item>
              <Typography variant="subtitle1" style={{color: '#000'}}>{message}</Typography>
            </Grid>
          )
        }
        <Grid item>
          <img src={process.env.PUBLIC_URL+"/images/qr.png"} alt="QR Code"/>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">SCAN PRODUCT QR CODE TO VERIFY</Typography>
        </Grid>
      </Grid>
      <footer className="site-footer" id="footer">
        <p className="site-footer__fineprint" id="fineprint">
          © eAuthenticity 2021 | All Rights Reserved
        </p>
      </footer>
    </div>
  );
}