export const RestService = {
    get: async function(url, headers) {
        headers["Content-Type"] = "application/json";
        headers["sysbrew-x-token"] = localStorage.getItem('eauthenticity.customer.session');
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
        // if(url.includes('?')){
        //     url= `${url}&user_type=${localStorage.getItem('eauthenticity.customer.user_type')}&user_code=${localStorage.getItem('eauthenticity.customer.user_code')}`
        // } else {
        //     url= `${url}?user_type=${localStorage.getItem('eauthenticity.customer.user_type')}&user_code=${localStorage.getItem('eauthenticity.customer.user_code')}`
        // }
        return fetch(url, {
            method: "GET",
            headers: headers
        })
        .then(response => {
            if (response.status === 500) {
                localStorage.removeItem('eauthenticity.customer.user');
                localStorage.removeItem('eauthenticity.customer.session');
                localStorage.removeItem("acumen.branch");
                window.location.reload(true);
                const error = (response && response.message) || response.statusText;
                return Promise.reject(error);
            }
            
            return response;
        });
    },
    delete: async function(url, headers) {
        headers["Content-Type"] = "application/json";
        // if(url.includes('?')){
        //     url= `${url}&user_type=${localStorage.getItem('eauthenticity.customer.user_type')}&user_code=${localStorage.getItem('eauthenticity.customer.user_code')}`
        // } else {
        //     url= `${url}?user_type=${localStorage.getItem('eauthenticity.customer.user_type')}&user_code=${localStorage.getItem('eauthenticity.customer.user_code')}`
        // }
        headers["sysbrew-x-token"] = localStorage.getItem('eauthenticity.customer.session');
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
         return fetch(url, {
             method: "DELETE",
             headers: headers
         });
    },
    post:  async function(url, headers, body) {
        headers["Content-Type"] = "application/json";
        // if(url.includes('?')){
        //     url= `${url}&user_type=${localStorage.getItem('eauthenticity.customer.user_type')}&user_code=${localStorage.getItem('eauthenticity.customer.user_code')}`
        // } else {
        //     url= `${url}?user_type=${localStorage.getItem('eauthenticity.customer.user_type')}&user_code=${localStorage.getItem('eauthenticity.customer.user_code')}`
        // }
        headers["sysbrew-x-token"] = localStorage.getItem('eauthenticity.customer.session');
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
         return fetch(url, {
             method: "POST",
             headers: headers,
             body: body ? JSON.stringify(body) : null
         })
         .then(response => {
            console.log(response.headers)
            if (response.status === 500) {
                localStorage.removeItem('eauthenticity.customer.user');
                localStorage.removeItem('eauthenticity.customer.session');
                localStorage.removeItem("acumen.branch");
                window.location.reload(true);
                const error = (response && response.message) || response.statusText;
                return Promise.reject(error);
            }
            return response;
        });
    },
    postFormData:  async function(url, headers, body) {
        headers["enctype"] = "multipart/form-data";
        // if(url.includes('?')){
        //     url= `${url}&user_type=${localStorage.getItem('eauthenticity.customer.user_type')}&user_code=${localStorage.getItem('eauthenticity.customer.user_code')}`
        // } else {
        //     url= `${url}?user_type=${localStorage.getItem('eauthenticity.customer.user_type')}&user_code=${localStorage.getItem('eauthenticity.customer.user_code')}`
        // }
        headers["sysbrew-x-token"] = localStorage.getItem('eauthenticity.customer.session');
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
         return fetch(url, {
             method: "POST",
             headers: headers,
             body: body
         })
         .then(response => {
            console.log(response.headers)
            if (response.status === 500) {
                localStorage.removeItem('eauthenticity.customer.user');
                localStorage.removeItem('eauthenticity.customer.session');
                localStorage.removeItem("acumen.branch");
                window.location.reload(true);
                const error = (response && response.message) || response.statusText;
                return Promise.reject(error);
            }
            return response;
        });
    }
};
