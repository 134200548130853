import React, { useContext } from "react";
import "../css/login.css";
import { useHistory } from "react-router-dom";
import { AppContext } from "../AppContextProvider";
import { useState } from "react";
import MasterService from "../Services/MasterService";
import { validateEmail } from "../Utils";
import ScanService from "../Services/ScanService";
import ItemSelectionAsync from '../components/ItemSelectionAsync'
import { Grid, makeStyles, Typography } from "@material-ui/core";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { PATHS } from "../Config";

const useStyles = makeStyles((theme) => ({
	formAutoClass: {
		zIndex: 3,
    marginBottom: 9,
    marginTop: 3,
	},
}));


export default function BasicDetails(props){
  const history = useHistory();
  const {
    state,
    token,
    setMobile,
    setStep,
    steps,
    isLoading,
  } = useContext(AppContext);
  const classes = useStyles()

  const [sourceTypes, setSourceTypes] = useState([])
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({email: '', fullname: '', dob: '', source: null})

  React.useEffect(() => {
    if(!token){
      history.push(PATHS.LANDING)
    }
    if(steps && steps.basic){
      if(!steps.thankyou){
        history.push(PATHS.THANK_YOU)
      } else {
        history.push(PATHS.LANDING)
      }
    }
    Promise.all([MasterService.getSourceTypes()])
    .then(data => {
      if(data[0]){
        setSourceTypes(data[0])
      }
    })
  }, [true])

  const handleChange = e => {
    e.persist()
    if(e.target.name === 'source'){
      setFormData(formData => ({...formData, [e.target.name]: e.target.value === 'default' ? null : e.target.value}))
    } else {
      setFormData(formData => ({...formData, [e.target.name]: e.target.value}))
    }
  }

  const saveDetails = (e) => {
    if(formData.email && validateEmail(formData.email) && formData.fullname && formData.address1 && formData.pincode) {
      isLoading(true)
      setMobile(formData.mobile)
      setMessage('')
      let body = {
        source: formData.source,
        email: formData.email,
        name: formData.fullname,
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        pincode: formData.pincode,
        dob: formData.dob,
      }
      ScanService.submitProfileInfo(body)
      .then(data => {
        if(data.success) {
          setStep('basic', true)
          history.push(PATHS.THANK_YOU);
        } else if (!data.success && data.opened){
          history.push(PATHS.LANDING);
        } else {
          setMessage(data.message);
        }
      })
      .catch(error => {
        setMessage("Something went wrong. Please try again.");
      })
      .finally(() => isLoading(false))
    } else {
      if(!validateEmail(formData.email)) {
        setMessage("Please enter valid email address")
      } else {
        setMessage("Please enter all fields")
      }
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center container" style={{paddingTop: 250}}>
        <div className="card py-4 px-3" style={{height: 'auto'}}>
            <h5 className="m-0">Complete profile details</h5>
            <div className="flex flex-row mt-3">
                <select name="source" required style={{width: '100%', marginBottom: 9}} className="form-control" defaultValue={"default"} aria-label="Default select example" onChange={handleChange}>
                  <option value="default">Where did you buy our Product?</option>
                  {
                    sourceTypes.map(s => <option key={s.key} value={s.key}>{s.label}</option>)
                  }
                </select>
                <input type="tel" disabled className="form-control" style={{width: '100%', marginBottom: 9}} placeholder="Enter your phone number" defaultValue={state.mobile}/>
                <input type="email" required name="email" className="form-control" style={{width: '100%', marginBottom: 9}} placeholder="E-mail *" onChange={handleChange}/>
                <input type="text" required name="fullname" className="form-control" style={{width: '100%', marginBottom: 9}} placeholder="Full name *" onChange={handleChange}/>
                <label htmlFor="address1" className="text-left ml-2" style={{width: '100%'}}>Address</label>
                <input type="text" name="address1" required className="form-control" style={{width: '100%', marginBottom: 9}} placeholder="Address Line 1 *" onChange={handleChange}/>
                <input type="text" name="address2" className="form-control" style={{width: '100%', marginBottom: 9}} placeholder="Address Line 2" onChange={handleChange}/>
                <ItemSelectionAsync
                  size={"small"}
                  label=''
                  placeholder='Pincode *'
                  required={true}
                  //value={lead.offPincodeOb || null} 
                  value={null} 
                  optionLabel='pincode'
                  labelTemplate={option => option.pincode}
                  style={{zIndex: 500, marginTop: 0, marginLeft: 0}}
                  formClass={classes.formAutoClass}
                  selected={(value) => {
                    console.log(value)
                    setFormData(formData => ({...formData, pincode: value.pincode, state: value.state, city: value.city}))
                    //setLead(lead => ({...lead, offPincodeOb: value, off_city: value.city, off_state: value.state}));
                    //setErrorList(e => ({...e, offPincodeOb : !value, off_city: !value.city, off_state: !value.state}));
                  }}
                  renderOption={(option) => {
                    return (
                      <Grid container alignItems="center">
                        <Grid item>
                          <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                        <span style={{ fontWeight: 400 }}>
                          {option.pincode}
                        </span>
                        <Typography variant="body2" color="textSecondary">
                          {`${option.city}, ${option.state}`}
                        </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}/>
                {
                  formData.city && (
                    <input type="text" name="city" value={formData.city} disabled={true} className="form-control" style={{width: '100%', marginBottom: 9}} placeholder="City" onChange={handleChange}/>
                  )
                }
                {
                  formData.city && (
                    <input type="text" name="state" value={formData.state} disabled={true} className="form-control" style={{width: '100%', marginBottom: 9}} placeholder="State" onChange={handleChange}/>
                  )
                }
                {/* <input type="number" name="pincode" className="form-control" style={{width: '100%', marginBottom: 9}} placeholder="Pincode" onChange={handleChange}/> */}
                <label htmlFor="dob" className="text-left ml-2" style={{width: '100%'}}>Date of Birth</label>
                <input type="date" name="dob" className="form-control" style={{width: '100%', marginBottom: 9}} placeholder="Enter Date of birth" id="pass" onChange={handleChange}/>
            </div>
            <div className="text-danger text-left mr-2">
              <label>{message}</label>
            </div>
            <div className="text-center mt-3" style={{fontSize: 18}}><span className="font-weight-bold text-danger cursor" onClick={saveDetails}>Proceed</span></div>
        </div>
    </div>
  );
}