import React, { useContext } from "react";
import OtpInput from 'react-otp-input';
import { useHistory } from "react-router-dom";
import { AppContext } from "../AppContextProvider";
import { useState } from "react";
import ScanService from "../Services/ScanService";
import { PATHS } from "../Config";

export default function Validate(props){
  const history = useHistory();
  const {
    state,
    token,
    setStep,
    steps,
    isLoading,
  } = useContext(AppContext);
  const otpLength = 6;
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({otp: ''})
  const [disabled, setDisabled] = useState(true)
  const [email, setEmail] = useState(null)

  React.useEffect(() => {
    if(!token){
      history.push(PATHS.LANDING)
    }
    if(steps && steps.validate){
      if(!steps.basic){
        history.push(PATHS.BASIC_DETAILS)
      } else if(!steps.thankyou){
        history.push(PATHS.THANK_YOU)
      } else {
        history.push(PATHS.LANDING)
      }
    }
    if(state.email){
      var maskid = state.email.replace(/^(.)(.*)(.@.*)$/,
          (_, a, b, c) => a + b.replace(/./g, '*') + c
      );
      setEmail(maskid)
    }
    startTimer()
  }, [true])

  const startTimer = () => {
    setDisabled(true)
    setTimeout(() => {
      setDisabled(false)
    }, 30000)
  }

  const handleChange = otp => {
    setFormData(formData => ({...formData, otp}))
    setMessage('')
  }

  const resendOTP = (e) => {
    setMessage('')
    isLoading(true)
    ScanService.requestOTP(state.mobile, state.source, state.clientId, state.productId, state.sequence)
    .then(data => {
      startTimer()
      if(data.success) {
        setMessage('OTP Resent')
        setTimeout(() => setMessage(''), 3000)
      }
    })
    .catch(error => {
      setMessage(error.message)
    })
    .finally(() => isLoading(false))
  }
  
  const validateOTP = (e) => {
    if(formData.otp.length === otpLength) {
      isLoading(true)
      setMessage('')
      ScanService.validateOTP(formData.otp)
      .then(data => {
        if(data.success) {
          setStep('validate', true)
          history.push(data.redirect_url || PATHS.BASIC_DETAILS);
        } else {
          setMessage(data.message)
        }
      })
      .catch(error => {
        setMessage(error.message)
      })
      .finally(() => isLoading(false))
    } else {
      setMessage("Please enter valid OTP")
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center container">
      <div className="d-flex justify-content-center align-items-center container">
          <div className="card py-5 px-3">
              <h5 className="m-0">{state.email ? `Email verification`: `Mobile phone verification`}</h5>
              {
                state.email ?(
                  <span className="mobile-text">
                    Enter the code we just send on your email <b className="text-danger">{email}</b>
                  </span>
                  ) : (
                    <span className="mobile-text">
                      Enter the code we just send on your mobile phone <b className="text-danger">+91 {state.mobile}</b>
                    </span>
                  )
              }
              <div className="d-flex flex-row mt-5 justify-content-center" style={{marginLeft: 12}}>
                  <OtpInput
                    autoFocus={true}
                    value={formData.otp}
                    onChange={handleChange}
                    numInputs={otpLength}
                    inputStyle={otpLength === 4 ? 'form-control w-3em' : 'form-control w-2em'}
                    />
              </div>
              <div className="text-danger text-center mr-3 mt-2">
                <label>{message}</label>
              </div>
              <div className="text-center mt-4" style={{fontSize: 18}}>
                <span className="font-weight-bold text-danger cursor mt-5" onClick={validateOTP}>NEXT</span>
                {/* <span className="d-block mobile-text">or</span> */}
                <div>
                  <span className="d-block mobile-text mb-2 mt-3">Don't receive the code?</span>
                  <span className={disabled ? "font-weight-bold text-secondary cursor mt-5": "font-weight-bold text-danger cursor mt-5"} onClick={() => !disabled && resendOTP()}>RESEND</span>
                </div>
              </div>
          </div>
      </div>
    </div>
  );
}